// const bot = require('./images/bot.svg').default;
const email = require('./images/email.svg').default;
// const briefcase = require('./images/briefcase.svg').default;
const settings = require('./images/settings.svg').default;
const moon = require('./images/moon.svg').default;
const backgroundClassic = require('./images/backgrounds/classic.jpg');
const backgroundXp = require('./images/backgrounds/xp.jpg');
const backgroundClouds = require('./images/backgrounds/clouds.jpg');
const backgroundLogo = require('./images/backgrounds/logo.jpg');
const backgroundVaporwave = require('./images/backgrounds/vaporwave.jpg');
const backgroundSocial = require('./images/backgrounds/social.jpg');

const apps = {
//  messenger: 'Chat',
  work: 'Work',
  contact: 'Contact',
  response: 'Response',
  shutdown: 'Shutdown',
  settings: 'Settings',
};

const icons = {
  /* chat: {
    url: bot,
    alt: 'Icon of bot',
  }, */
  /* work: {
    url: briefcase,
    alt: 'Icon of briefcase',
  }, */
  contact: {
    url: email,
    alt: 'Icon of e mail',
  },
  response: {
    url: email,
    alt: 'Icon of e mail',
  },
  shutdown: {
    url: moon,
    alt: 'Icon of moon',
  },
  settings: {
    url: settings,
    alt: 'Icon of settings',
  },
};

const resumeLink = 'https://linkedin.com';

const contact = {
  content: 'Crypto trading cards',
  // emailLink: 'mailto:trislit@gmail.com',
  // linkedin: 'https://linkedin.com/',
  // github: 'http://github.com/ronin',
};
const response = {
  content: 'RE: Crypto trading cards',
  github: 'http://github.com/',
};

/* const work = [
  {
    title: 'website',
    url: '//ronin.com/',
    copy: '',
  },
  {
    title: 'other link',
    url: '//trislit.io',
    copy: 'heres another site for trislit.',
  },
]; */

// const initialResponse = "Hi there, I'm Ronin";

/* const changeInputResponse = {
  free: 'Feel free to ask me whatever you want. 🚀',
  options: 'I\'m waiting. 🔮',
}; */

// TODO: update URLs to be correct
const systemSettingsBackground = [
  {
    name: 'Classic',
    url: backgroundClassic,
  },
  {
    name: 'XP',
    url: backgroundXp,
  },
  {
    name: 'Clouds',
    url: backgroundClouds,
  },
  {
    name: 'Logo',
    url: backgroundLogo,
  },
  {
    name: 'Vaporwave',
    url: backgroundVaporwave,
  },
  {
    name: 'Social',
    url: backgroundSocial,
  },
];

const systemSettingsTheme = ['Light', 'Dark'];

const API = 'https://portfolio-chatbot.herokuapp.com/api/message';

module.exports = {
  apps,
  icons,
  resumeLink,
  contact,
  response,
  // work,
  // initialResponse,
  // changeInputResponse,
  systemSettings: {
    background: systemSettingsBackground,
    theme: systemSettingsTheme,
  },
  API,
};
